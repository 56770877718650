
.modal-header{
    padding: 10px 15px;
    background-color: #333;
}
.modal-title{
    font-size: 18px;
    color: #fff;
}
.modal-footer{
    padding: 5px 15px;
}

.actionBtnContainer{
    display: flex;
    align-items: center; 
    justify-content: space-between;
    margin-bottom: 10px;
    &__btnBox{
        display: flex;
        align-items: center; 
        button{
            margin-left: 10px;
        }
}
@media (max-width: 600px){
    position: relative;
    right: 0;
    &__btnBox{
        display: block;
        align-items: center; 
}
button{
    display: block;
    margin: 10px;
}
}
}

.actionBtnContainer > button{
    border: 0;
    margin: 0 5px;
    font-size: 14px;
}

.categoryPage{
    margin-top: 40px;
    display: 'flex';
    justify-content: 'space-between';

    &__titleBox{
        text-align: center;
        margin: 10px auto;
    }
}   
