.scroll-block{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 60px;
    position: fixed;
    bottom: 20px;
    right: 50px;
    background: rgba(0, 0, 255, 0.9);
    padding: 10px;
    cursor: pointer;
    &:hover{
    
    color: white;
    background: blue;
    border: 1px solid white;
    }
 
    svg{
        font-size: 40px;
        margin: 0 auto;
    }
}