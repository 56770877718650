$primary-color: #3498db;

.loaderFullScreen{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); // полупрозрачный фон
    display: flex;
    align-items: center;
    justify-content: center;
    &__animated-element {
        width: 100px; // настройте размер кружка или другого элемента
        height: 100px;
        background-color: $primary-color;
        border-radius: 50%;
        animation: moveAround 2s infinite linear; // настройте параметры анимации
      }
}
@keyframes moveAround {
    0% {
      transform: translateX(0) rotate(0);
    }
    25% {
      transform: translateX(100px) rotate(90deg);
    }
    50% {
      transform: translateX(100px) translateY(100px) rotate(180deg);
    }
    75% {
      transform: translateX(0) translateY(100px) rotate(270deg);
    }
    100% {
      transform: translateX(0) rotate(360deg);
    }
  }