.renderSettingOrderProductModal{


    &__iconAddsBox{
        margin: 10px;
        cursor: pointer;
        svg{
            width: 25px;
            height: 25px;
        }
        &:hover{
            svg{
                color: blue;
            }
        }
    }
}

.additionalField{
    &__itemHeader{
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 10px;
    }
    &__titleHeader{
        width: 25%;
        font-size: 17px;
        color: #434343;
    }
    &__descriptionHeader{
        width: 35%;
        font-size: 17px;
        color: #353535;
    }
    &__typeHeader{
        font-size: 17px;
        color: #353535;
        width: 25%;
    }
    &__settingBlock{
        position: absolute;
        right: 0px;
        top: 5px;
        cursor: pointer;
        &:hover{
            svg{
                color: #f44336;
            }
        }
    }
    &__settingBlock.edit{
        right: 20px;
        &:hover{
            svg{
                color: #0084ff;
            }
        }
    }
    &__modalAdditionalBlock{
        position: relative;
    }
    &__items{
        min-height: 100px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    &__item{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        justify-content: space-around;
        padding: 5px;
        border: 1px solid #cecece;
        position: relative;
        margin-left: 30px;
        margin-right: 5px;
        border-radius: 5px;
        &:hover{
            background-color: #f5f5f5;
        }
    }

    &__indexNumber{
        width: 23px;
        height: 23px;
        position: absolute;
        padding-top: 1px;
        text-align: center;
        left: -30px;
        border: 1px solid #cecece;
        border-radius: 50%;
    }
    &__title{
        width: 25%;
        margin-left: 15px;
        word-break: break-all;
        overflow: hidden;
        text-align: left;
    }
    &__description{
        width: 35%;
        word-break: break-all;
        overflow: hidden;
        text-align: left;
    }
    &__viewType{
        width: 25%;
    }
    &__type{
        &__description{
            max-width: 150px;
            word-break: break-all;
            overflow: hidden;
            text-align: left;
        }
    }
}


.blocks{

    display: flex;
    align-items: center;
    justify-content: space-between;

    .block{
        min-height: 300px;
        
    }
}