.galleryPage{
margin-top: 20px;
    &__renderItem{
        margin-bottom: 10px;
        background-color: #fff;
        border: 1px solid rgba(0,0,0,.125);
        border-radius: 0.25rem;
    }
    &__headerRow{
        position: relative;
        padding: 5px;
        border: 1px solid rgba(0,0,0,.125);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__name{
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        &:hover{
                color: rgb(119, 140, 232);
        }
    }
    &__imgRow{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__quantityAddedPhoto{
        margin: 10px 20px 0 10px;
    }
}