.homePageContainer{
    display: flex;
    max-width: 800px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    flex-wrap:wrap;
    &__mainBox{
        padding: 10px;
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 200px;
        border: 1px solid rgb(68,70,84);;
        transition: 0.5s all;
        cursor: pointer;
        a{
            text-decoration: none;
        }
        &:hover{
            .homePageContainer__iconContainer {
                svg{
                    color: rgb(161, 103, 103);
                }
            }
                .homePageContainer__text {
                        color: rgb(161, 103, 103);
            }
        }
    }
    &__iconContainer{
        svg{
            color: rgb(222, 222, 222);
            height: 50px;
            width: 50px;
        }
    }
    &__text{
        color: rgb(222, 222, 222);
        font-size: 17px;
        padding-top: 10px;
    }
}