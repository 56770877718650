.htmlMailingContent{
    max-width: 600px;
        &__itemsProduct{
            display: flex;
            align-items: center;
            overflow-x: auto;
            &__item{
                height: 200px;
                padding: 10px;
            }
            &__imgBox{
                height: 150px;
            img{
                height: 100%;
                height: 100px;
                width: auto;
            }
            }
            
        }
        
}