.containerButtonVisible{
margin: 10px;

    &__row{
    display: flex;
    align-items: center;
    justify-content: center;
 }
    &__box{
    border: 1px solid rgb(112, 112, 112);
    padding: 5px;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 15px;
    font-weight: 600;
    &:hover{
    background-color: rgba(0, 0, 0, 0.03);

    }
 }
 &__box.active{
    border-color: rgb(216, 126, 16);
 }
}