.pictureMiniRow{
    padding-bottom: 20px;
    &__titleBlock{
         display: flex;
         align-items: center;
         justify-content: space-between;
         margin: 10px;
    }
    &__title{
        font-size: 20px;
    }
    &__closeIcon{
        font-size: 20px;
        cursor: pointer;
        &:hover{
            color: red;
        }
    }
    &__items{
        overflow-x: auto;
        display: flex;
        padding: 10px 0;
    }
    &__imgBox{
        cursor: pointer;
        width: auto;
        height: 100px;
        // overflow: hidden;
        text-align: center;
        padding: 0 10px;
        position: relative;
        img{
            width: auto;
            height: 100%;
            object-fit: contain;
        }
    }
    &__imgBox.active{
        background-color: red;
    }
    &__imgBox.mainImg{
        background-color: aquamarine;
    }
    &__textHidePicture{
        position: absolute;
        bottom: 0;
        right: 20px;
        display: flex;
        width: 50px;
        justify-content: center;
        font-size: 18px;
        text-align: center;
        font-weight: 600;
        color: red;
    }
    &__views{
        position: absolute;
        top: -5px;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        border-radius: 50%;
        width: 20px;
        background: red;
        color: white;
    }
    &__views.blue{
        background: rgb(0, 153, 255);
    }
}