.containerProductPage {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    &__item {
        margin: 10px;
        height: 250px;
        background-color: gray;
        -webkit-transition: all 0.5s ease-out;
        &:hover{
            img{
                transform: scale(1.1);
            } 
            h4{
                color: white;
            }
        }
    }

    &__blockImg {
        height: 210px;
        overflow: hidden; // Скрываем излишки изображения

        img {
            text-align: center;
            margin: 0 auto;
            height: 100%;
            width: auto; // Сохраняем пропорции изображения
        }
    }
    h4{
        margin-top: 10px;
        text-align: center;
        color: black;
    }
  
}
