.productionsMaterial{
    &__mainTitle{
        text-align: center;
        margin: 10px auto;
        font-size: 25px;
    }
    &__topButtonBlock{
        padding-bottom: 10px;
        border-bottom: 1px solid gray;
    }
    &__newObject{

    }
    &__itemMaterialItem{
        padding: 5px;
        margin-bottom:10px ;
        background-color: rgba(128, 128, 128, 0.2);
        border: 1px solid gray;
        &__inputMaterialPrice{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    &__materialArrayTitle{
        margin: 10px;
        font-size: 20px;
        text-align: center;
    }

    &__viewAdded {

        &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__item {
            display: flex;
            align-items: center;
            flex-basis: calc(25% - 55px);
            justify-content: space-between;
            padding: 5px;
            margin: 5px auto;
            border: 1px solid gray;
            position: relative;
            width: 90%;
        }
        &__item.edited-item{
            background-color: rgba(0, 0, 255, 0.267);
        }
        &__name{
            width: 34%;
        }
        &__dimensionValue, &__unitPrice,&__totalCost {
            width: 22%;
            text-align: center;
        span.new{
            color: green;
            font-weight: 600;
        }
        span.oldValue{
            color: red;
            text-decoration: line-through;
        }
        }
        &__totalAmount{
            background-color: rgba(128, 128, 128, 0.295);
            width: 300px;
            text-align: center;
            margin: 20px  auto;
            position: relative;
            &__text{

            }
            &__detailsAmount{
                padding-top: 15px;
                height: 100px;
            }
        }
    }
}