.objRender{
    &__row{
        max-width: 1000px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    &__title{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        margin: 0 auto;
    }
    &__mainBox{
        min-height:170px;
        width: 200px;
        margin: 10px;
        border-bottom: solid 1px #566856;
    }
    &__name{
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }
    &__description{
        font-size: 16px;
        padding: 0 10px;

    }
    &__btnBox{
        display: flex;
        flex-direction: column;
    }
    &__btn{
        text-decoration: none;
        background: #3d7a5f;
        color: rgb(255, 255, 255);
        margin: 5px 10px;
        padding: 10px 25px;
        position: relative;
        border: 1px solid #3d7a5f;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        transition: 0.3s all;
        outline:none;
    &:hover{
      background: rgba(0,0,0,.1);
      color: #000000;
    }
      }

    &__listType{
        max-width: 1000px;
        margin: 0 auto;
    }
    &__listRow{
        padding: 10px;
    }
    &__imgContainer{
        height: 120px;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            max-height: 100%;
            width: auto;
        }
    }
    &__item{
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-height: 200px;
        padding: 5px;
        background-color: white;
        border-radius: 5px;
        border: 1px solid #d9d9d9;
        transition: 0.3s all;
        &:hover{
            border: 1px solid #00aeff;
            box-shadow: 0 2px 10px 0 rgba(154, 154, 154, 0.4);
        }
    }
    &__itemContentBox{
        height: 100%;
        min-width: 400px;
    }
    &__itemName{
        font-size: 16px;
        cursor: pointer;
        color: black;
        &:hover{
            color: #3d7a5f;
        }
    }
    &__itemDescription{
        font-size: 12px;
    }
}