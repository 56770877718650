.reusableTable{
    &__emailsArray{
        position: relative;
        color: rgb(255, 255, 255);
        span{
        margin-left: 20px;
        }
        &__icon{
            position: absolute;
            left: 0;
            top: 0;
        }
        &:hover{
            color: rgb(31, 137, 208);
            cursor: pointer;
         }
    }
    &__listItemEmail{
        color: rgb(255, 255, 255);
        &:hover{
            color: rgb(31, 137, 208);
            cursor: pointer;
         }
    }
    .settingIconBlock{
        z-index: 9999;
    }
}