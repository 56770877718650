.userProductContainer{
    &__message{
        font-weight: 600;
        margin-top: 10px;
        text-align: center;
    }
    &__row{
        display: flex;
        justify-content: space-between;
    }
    &__productBox{
        width: 60%;
        max-height: 500px;
        overflow-y: auto;
    }
    &__textBox{
        position: relative;
        width: 100%;
    }
    &__infoBox{
        width: 40%;
        &__productLength{
            font-weight: 600;
            margin: 10px;
            span{
                margin-right: 10px
            }
        }
    }
    &__fullName{
        font-weight: 600;
        text-align: center;
    }
    &__quantity{
        position: absolute;
        right: 10px;
        bottom: 25px;
        border-bottom: 1px solid rgba(128, 128, 128, 0.432);
        span{
            font-weight: 600;
        }
    }
    &__createdAt{
        position: absolute;
        right: 10px;
        bottom: 5px;
    }
    &__itemProd {
        display: flex;
        margin: 5px;
        border: 1px solid gray;
        padding: 10px;
      
    }
      &__imgBox{
            height: 80px;
            width: 150px;
            display: flex;
            justify-content: center;
            position: relative;
            z-index: 99999999;
            overflow: hidden;
            &__img{
                max-width: 150px;
                height: 100%;
            }
            img{

                height: 100%;
                width: auto;
            }
        
        }
}