.paginationComponent{
    max-width: 1200px;
    margin: 0 auto;
    *{
        outline: none !important;
        }
    &__mainContainer{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__buttonNext, &__buttonBack ,&__buttonNumber{
        padding: 10px;
        font-size: 16px;
        margin: 5px;
        border: none;
        color: #46587a;
        transition: all .3s;
        &:hover{
            background-color: rgb(49, 49, 49);
            border: none;
            color: rgb(236, 236, 236);
        }
        :active, :hover, :focus {
            outline: 0;
            outline-offset: 0;
        }
    }
    &__buttonNumber{
        &:hover{
            background-color: rgb(49, 49, 49);
            color: rgb(236, 236, 236);
        border: none;
        outline: none;

        }
    }
   &__buttonNumber.active{
        color: red;
        outline: none;
    }
}