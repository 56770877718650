.QRCodePicture{

    &__imgContainer{
        height: 100px;
        width: 100px;
        background-color: gray;
        img{
            height: 100%;
            width: auto;
        }
    }

}