.siteSettingMainContainer{
    margin-top: 20px auto;
    &__titleModalSetting{
        font-size: 20px;
        margin: 0 auto;
        text-align: center;

    }   
    &__blockRowBox{
        max-width: 800px;
        min-height: 200px;
        margin: 0 auto;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #4e4e4e;
    }
    &__blockBox{
        height: 150px;
        width: 150px;
        border-radius: 5px;
        background-color: #e9e9e9;
        cursor: pointer;
        p{
            text-align: center;
            padding: 0 5px;
        }
        &:hover{
        background-color: #d4d4d4b4;

        }
    }
    &__iconBox{
        align-items: center;
        width: 100%;
        margin: 5px auto;
        svg{
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            margin: 0 auto;
        }
    }
h2{
    text-align: center;
    margin-top: 30px;
}    
    .siteSetting{
    max-width: 1000px;
    text-align: center;
    margin: 0 auto;
    li{
        margin: 5px;
        height: 35px;
        transition: all 0.3s;
        align-items: center;
        display: flex;
        
        a{
            text-align: left;
            color: #cecece;
            width: 100%;
        }

    }
    li::before{
        content: "";
        height: 25px;
        width: 25px; 
        background-color: rgb(196, 196, 196);
        border-radius: 50px;
        padding: 10px;
        margin: 0 10px ;
    }
    li:hover{
        background-color: #87878788;
        border-radius: 5px;
        opacity: 0.8;
        transition: all 0.3s;
    }
    a{
        font-size: 16px;
        color: #4e4e4e;
        text-decoration: none;
    
    }

}
.cardHeader{
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;
    border-bottom: 1px solid #cecece;
}



@media(max-width: 500px){
    margin-top: 20px auto;
    h2{
        text-align: center;
        margin: 20px 0;
        font-size: 20px;
    }    
        .siteSetting{
        max-width: 1000px;
        text-align: center;
        margin: 0 auto;
        li{
            margin: 5px;
            height: 35px;
            transition: all 0.3s;
            align-items: center;
            display: flex;
    
        }
        li::before{
            content: "";
            height: 25px;
            width: 25px;
            background-color: rgb(196, 196, 196);
            border-radius: 50px;
            padding: 10px;
            margin: 0 10px ;
        }
        li:hover{
            background-color: #94deb4;
            border-radius: 5px;
            opacity: 0.8;
            transition: all 0.3s;
        }
        a{
            font-size: 16px;
            color: #4e4e4e;
            text-decoration: none;
        
        }
    
    }
    .cardHeader{
        display: flex;
        justify-content: space-between;
        padding: 20px 20px;
        border-bottom: 1px solid #cecece;
    }
    
    
}
    }