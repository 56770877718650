.categoryContainer{
    max-width: 1200px;
    margin: 0 auto;
    span{
        display: none;
    }
    &__linkContainer{
        display: flex;
        align-items: center;

    }
    &__itemContainer{
        max-width: 95%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        // justify-content: center;
    }
    &__mainContainer.hideCategory{
        background-color: #7448ff;
    }
    &__item.active{
                color: #7448ff;
                border-color: #7448ff;
        }
    &__item{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 5px 10px;
        text-transform:uppercase;
        border: 1px solid #cecece;
        margin: 3px;
        font-size: 17;
        transition: 0.5s all;
        cursor: pointer;
        text-align: center;

        &:hover{
            background-color: #ffffff39;
            border-color: #7448ff;
        }
    }


    
        @media(max-width: 500px){
            &__mainContainer{
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              h2{
              margin: 0 auto;
              }
          }   

          &__item{
            display: block;
            align-items: center;
            flex-wrap: wrap;
            padding: 5px 10px;
            text-transform:uppercase;
            font-size: 17;
            transition: 0.5s all;
            cursor: pointer;
            text-align: center;
        }
          }
}