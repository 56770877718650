.contentItem{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-around;
    padding: 5px;
    border: 1px solid #cecece;
    position: relative;
    margin-left: 30px;
    margin-right: 5px;
    border-radius: 5px;
    &:hover{
        background-color: #f5f5f5;
    }
    &__indexNumber{
        width: 23px;
        height: 23px;
        position: absolute;
        padding-top: 1px;
        text-align: center;
        left: -30px;
        border: 1px solid #cecece;
        border-radius: 50%;
    }
    &__title{
        width: 25%;
        margin-left: 15px;
        word-break: break-all;
        overflow: hidden;
        text-align: left;
    }
    &__description{
        width: 35%;
        word-break: break-all;
        overflow: hidden;
        text-align: left;
    }
    &__viewType{
        width: 25%;
    }
    &__type{
        &__description{
            max-width: 150px;
            word-break: break-all;
            overflow: hidden;
            text-align: left;
        }
    }
    &__settingBlock{
        position: absolute;
        right: 0px;
        top: 5px;
        cursor: pointer;
        &:hover{
            svg{
                color: #f44336;
            }
        }
    }
    &__settingBlock.edit{
        right: 20px;
        &:hover{
            svg{
                color: #0084ff;
            }
        }
    }
}