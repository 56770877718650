.renderProductOrder{
    position: relative;
    margin-top: 15px;
    border-bottom: 1px solid #cecece;
    padding: 10px;
    margin: 20px 0 ;
    &:hover{
        background-color: #ededed;
    }
    &__itemsDataRow{
        margin: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__title{
            display: inline-flex; 
            align-items: left;
            font-weight: 600;
        }
        &__line{
            height: 1px;
            flex-grow: 1; 
            background-color: #d3d3d3;
            margin: 0 10px;
        }
        &__obj{
            width: 100px;
        }
    }
    &__imgBox{
        height: 100px;
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            height: auto;
            max-height: 100%;
            width: 100%;
            max-width: 100%;
        }
    }
    &__name{
        margin-top: 10px;
        a{
            text-align: center;
            color: black;
        }
    }
    &__visibilityPrice{
        
    }
    &__price{
        position: absolute;
        bottom: 5px;
        right: 10px;
    }
    &__priceTitle{

    }
    &__priceValue{
        color: rgb(136, 136, 255);
        font-weight: 600;
    }
    &__noPriceValue{
        color: red;
    }
    &__quantity{
        margin-top: 10px;
    }
    &__quantityTitle{

    }
    &__quantityValue{
        cursor: pointer;
    }
}
.renderProductOrder.completelyFilled{
    background-color: #00a04539;
}