.settingIconBlock{
    top: 0;
    color: white;
    // right: 0;
    cursor: pointer;
    &:hover{
        svg{
            color: rgb(119, 140, 232);
        }
    }
}