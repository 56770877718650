.containerMiniImg {
    margin: 10px 0;
    display: flex;
    overflow-x: auto;

// &__miniImg.active-mini {
//     opacity: 0.7;
// }
&__miniImg {
    max-height: 100px;
    max-width: 100%;
    position: relative;
    margin:5px;
    // padding: 5px;
    active-mini {
        opacity: 0.8;
    }
    img {
        height: 100%;
        max-width: 120px;
    }
    &:last-child{
        margin-right: 0
    }
}
}