.productList{
    padding: 10px 0;
    position: relative;
    a{
        text-decoration: none;
    }
&__container{
    max-width: 1300px;
    margin: 0 auto; 
}
&__containerIcon{
       text-align: center;   
}
&__mainItemBox{
    min-height: 300px;
}
        svg{
            color: rgb(186, 186, 186);
            height: 70px;
            width: 70px;
            transition: all 0.5s;
        }
&__text{
        text-align:center;
        font-size: 14px;
        font-weight: 600;
        transition: 0.3s all;
        text-transform: uppercase;
        width: 100%;
    }
    &__containerMainCategory{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        max-width: 1000px;
        margin: 0 auto;
        padding: 20px;

    }
    &__mainCategory{
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #dbdbdb;
        width: 300px;
        font-weight: 600px;
        height: 35px;
        color: #3f3f3f;
        cursor: pointer;
        margin: 10px;
        transition: 0.3s all;
        &:hover{
        background: #3f3f3f;
        color: white;
        }
    }
    &__mainCategory.active{
        background: #3f3f3f;
        color: white;
    }
        &__categoryItemContainer{
           cursor: pointer;
           display: none;
           width: 100%;
           margin: 0 auto;
           transition: 0.3s all;
        }
        &__categoryItemContainer.active{
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            justify-content: center;
            align-items: center; 
        }
        &__categoryItemBox{
            margin: 10px;
            width: 100px;
            height: 120px;
            text-align: center;
            align-items: center;
            &:hover{
                img{
                    color: #8b009a;
                }
                svg{
                    color: #d0d0d0;
                }
                .productList__text{
                    color: #c00000; 
                }
            }
        }
        &__imgContainer{
                height: 70px;
            img{
                height: 100%;
                width: auto;
                z-index: 1;
            }
        }
        @media (max-width: 700px){ 
            &__containerMainCategory{
                display: block;
            }
            &__mainCategory{
                margin: 10px auto;   
         }
        }
}