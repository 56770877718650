.renderOrderDetails{
    border-top: 1px solid rgb(207, 207, 207);
    margin: 15px auto;
    &__orderOnOperator{
        border: 1px solid rgb(207, 207, 207);
        margin-bottom: 5px;
        padding: 10px;
        span{
            font-weight: 600;
            font-size: 11px;
        color: #8888ff;
        }
    }
   &__allPriceContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
   }
   &__priceBox, &__priceDiscountedBox, &__fixedPercentage{
    display: flex;
    justify-content: center;
    p{
        color: rgb(112, 112, 112);
    }
    span{
        font-weight: 600;
        color: #8888ff;
    }
   }
    &__orderHistoryButton{
        color: rgb(255, 255, 255);
        cursor: pointer;
            padding: 10px;
            text-align: center;
            border-radius: 5px;
            background-color: rgb(47, 161, 255);
    &:hover{
        background-color: rgb(3, 70, 255);
        color: rgb(255, 255, 255);
    }
        }
        &__orderHistoryButton.disabled{
            background-color: rgb(211, 211, 211);
        }
        &__productOrderContainer{
            max-height: 600px;
            overflow-y: auto;
            margin-bottom: 10px;
        }
    &__settingIconBlock{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(207, 207, 207);
        width: 100%;
        height: 25px;

            svg: {
                color: rgb(47, 161, 255);
            }
    }
    &__totalPrice{
        color: rgb(136, 136, 255);
        font-weight: 600
    }
    &__noTotalPrice{
        color: red;
    }
    &__rowTotalAmount{
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
}