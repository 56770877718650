.footerCabinet {
&__container{
    margin: 5px;
    list-style-type: none;
    align-items: center;
    height: 48px;
    display: flex;
    padding-left: 10px;
    color: white;
    align-items: center;
}

}