.applicationChangeProfile{

    &__listItemRow.new{
    background-color: rgba(19, 98, 202, 0.164);   
    }
    &__listItemRow.confirmed{
    background-color: rgba(49, 202, 19, 0.164);   
    }
    &__listItemRow.rejected{
    background-color: rgba(202, 19, 19, 0.164);   
    }
    &__listItemRow{
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 400px;
        margin: 0 auto;
        padding: 5px;
        border: 1px solid gray;
        margin-bottom: 5px;
    }
    &__listItemRowTitle{
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 400px;
        margin: 0 auto;
    }
    &__title{
        font-weight: 600;
        width: 33%;
        padding-left: 10px;
    }
    &__name{
        width: 30%;
        position: relative;
        font-weight: 600;
    }
    &__svgNewBox{
        position: absolute;
        left: -30px;
        top: 0;
        animation: blink 1.5s ease-in-out 3;
        cursor: pointer;
        .svg{

     }
     .css-i4bv87-MuiSvgIcon-root{
         fill: rgba(19, 98, 202, 0.774);   
     }
    }
@keyframes blink {
    0% {
      opacity: 0;
      fill: #78c8e8;
    }
    50% {
      opacity: 1;
      
    }
    100% {
      opacity: 0;
    }
  }
    &__before{
        width: 37%;
        position: relative;
        &__text{
            max-width: 110px;
            font-size: 12px;
        }
        span{
            color: red;
        }
    }
    &__svgBox{
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        svg{
            width: 50px;
        }
    }
    &__after{
        width: 33%;
        position: relative;
    }
    &__svgOkBox{
        cursor: pointer;
        position: absolute;
        right: -30px;
        top: 0;
    }
    &__buttonContainer{
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    &__successText{
        font-weight: 600;
        font-size: 20px;
        text-align: center;
    }
    @media(min-width: 800px){
        &__listItemRowTitle{
            max-width: 600px;
        }
        &__listItemRow{
            max-width: 600px;
        }
    }
}