.order{
    align-items: center;
    position: relative;
&__statusOrder{
    font-weight: 600;
    letter-spacing: 3px;
}
&__statusOrder.gray{
    color: rgb(135, 135, 135);
}
&__statusOrder.green{
    color: #00a046;
}
&__statusOrder.greenDark{
    color: #00a6ff;
}
&__statusOrder.blue{ 
    color: #0033ff;
}
&__statusOrder.red{ 
    color: red;
}
&__statusOrder.orange{ 
    color: orange;
}
&__statusOrder.gold{
    color: #778ce8;
}
&__heading{
    display: block;
    position: relative;
}
&__heading::before{
    position: absolute;
    left: -25px;
    top: -15px;
    display: block;
    height: 50px;
    width: 8px;
    border-radius: 50px;
    transition: all .2s ease-in-out;
    background-color: rgb(0, 160, 70);
    content: "";
}
&__heading.gray::before{
    background-color: gray;
}
&__heading.red::before{
    background-color: red;
}
&__heading.orange::before{
    background-color: orange;
}
&__heading.green::before{
    background-color: #00a046;
}
&__heading.greenDark::before{
    background-color: #00a6ff;
}
&__heading.blue::before{
    background-color: #0033ff;
}
&__heading.gold::before{
    background-color: #778ce8;
}

 
&__numberOrderAdditional{
    color: #cc00ff;
    font-weight: 600;
}    



&__deleteBlock{
    cursor: pointer;

    &:hover{
        color: red;
    }
}
&__imgContainer{
    width: 100%;
    overflow-x: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    overflow-x: auto;
}
&__img{
     margin-right: 10px;
     max-width: 100px;
     height: 50px;
     margin-bottom: 5px;
    img{
        height: 100%;
        width: auto;
        text-align: center;
        width: auto;
    }
}
    &__leftContainer{
        margin-left: 20px;
    }
    &__iconContainer{
        align-items: center;
        position: absolute;
        right: 0;
        top: 0px;
        
        svg{
            
        }
    }
    &__rightContainer{ 
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 400px;
        overflow: hidden;

        
    }
}
.order__heading.mini::before {
    height: 20px;
    top: 0;
  }
  
.orderTrack{
    width: 500px;
    display: flex;
}
.orderStatus{
    // width:5px ;
    height:33.33% ;
    // background: #777;
    position: relative;
    font-size: 12px;
}

.orderStatus .point{
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 8px;
    background-color: #777;
    // top: -5px;
}
.orderStatus .point.active{
    background-color: #26A541;
}
.orderInfo{
    // height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // position: absolute;
    // top: -26px;
    transform: translateX(-50%);
}

.title{
    font-size: 12px;
    text-align: center;
    color: #797878;
}
.value{
    font-size: 13px;
    font-weight: 600;
    color: #8888ff;
    text-align: center;
}
.value.empty{
    color: red;
}
.imgRowContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    img{
        cursor: pointer;

        height: 70px;
        width: auto;
        margin: 0 10px;
        &:hover{
                opacity: 0.5;
        }
    }
   
}

.tabs{

    background-color: #f5f5f5;
    margin-bottom: 32px;
    position: relative;

    &__container{
        padding-left: 32px;
    padding-right: 32px;
    }
&__list{
    display: flex;
    flex-direction: row;
    max-width: 1280px;
    overflow: auto;
}

&__item{
    margin-right: 16px;
    transition: color 0.1s ease-in-out;
}
.active{
    box-shadow: inset 0 -2px 0 0 #00a046;
    color: #00a046;
}
&__link{
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    padding-left: 8px;
    padding-right: 8px;
    white-space: nowrap;
}

&__link:hover{
    box-shadow: inset 0 -2px 0 0 #00a046;
    color: #00a046;
}
.inactive {
    box-shadow: inset 0 -2px 0 0 #00a046;
    color: #00a046;
}

}


.orderHistoryButton{
    background: none;
    border: none;
    color: #3e77aa;
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-decoration: none;

    &:hover{
        color: #d80707;
    }
}

.events .orderStatus { 
    display: flex; 
    color: #999;
    // align-items: center;
  }

//   .orderStatus:last-child{
//         height: 0;
//     }
  .orderStatus.active{
    background: #26A541;
}
  .events .time { 
    position: relative;
    padding: 0 1.5em; 
    width: 100px;
}
.events .time.active::after{
    background: #26A541;

}
  
  .events .time::after { 
     content: "";
     position: absolute;
     z-index: 2;
     right: 0;
     top: 0;
     transform: translateX(50%);
     border-radius: 50%;
     background: #fff;
     border: 1px #ccc solid;
     width: 10px;
     height: 10px;
  
  
    }
  .events span {
    padding: 0 1.5em 1.5em 1.5em;
    position: relative;
    width: 100px;
  }
  
  .events span::before {
     content: "";
     position: absolute;
     z-index: 1;
     left: 0;
     height: 100%;
     border-left: 1px #ccc solid;
  }
  
  .events strong {
     display: block;
     font-weight: bolder;
  }
  
  .events { margin: 1em; width: 100%; }
  .events, 
  .events *::before, 
  .events *::after { box-sizing: border-box; font-family: arial; }
