.sidebar{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(255, 255, 255);
    padding: 0;
    padding-top: 60px;
    box-shadow: inset 0 0 2px gray;
}
.sidebar a{
    display: block;
}
.sidebar ul{
    margin: 0;
    padding: 0;
}
.sidebar ul li{
    list-style: none;
}
.sidebar ul li a{
    display: block;
    color: #333;
    padding: 5px 10px;
    box-sizing: border-box;
    margin: 5px 0 5px 10px;
    border-radius: 5px 0 0 5px;
    text-decoration: none;
}
.sidebar ul li a.active{
    background: #333;
    color: #fff;
}
