@import './components/all';
@import './button/all';
*{
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        // border-radius: 10px;
        background-color: #f9f9fd;
      }
      
      &::-webkit-scrollbar-thumb {
        // border-radius: 10px;
        background-color: orange;
      }
      
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
        // border-radius: 10px;
        background-color: #f9f9fd;
      }
}
body{
    // background-color: #b6daef;
    background-color: rgba(68,70,84,.9);
    color: #221f1f;
    // font: 62.5%/1.4 BlinkMacSystemFont, -apple-system, Arial, "Segoe UI", Roboto, Helvetica, sans-serif;
}
.sortComponent{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto;
    max-width: 1000px;
}
.styles-module_left__2lGdA{
    width: 15%;
}


.nav-itemContainer{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    li{
        padding: 10px;
        cursor: pointer;
    }
}
.navBarNameContainer{
    @media(max-width: 500px){
        display: none;
    }
}
//svg для категорий
.rct-collapse.rct-collapse-btn{
    position: relative;
    // &:hover{
    //     &::after{
    //         display: block;
    //     }  
    // }
    // &::after{
    //     content: 'Развернуть, свернуть';
    //     display: none;
    //     position: absolute;
    //     border-radius: 5px;
    //     left: -85px;
    //     top: 0;
    //     color: rgb(210, 210, 210);
    //     width: 100px;
    //     height: 40px;
    //     background-color: rgba(0,0,0,.5);
    // }
    svg{
        width: 25px;
        height: 25px;
        padding: 5px;
        color: rgb(40, 134, 216);
    }
}
.rct-node.rct-node-parent.rct-node-collapsed{
    margin-bottom: 20px;
}
.rct-node-icon{
    display: none;
}

.AddModalTextarea{
    width: 100%;
    min-height: 100px;
}