.renderTextEmptyProduct{
    min-height: 300px;
    padding: 20px;
    margin: 20px auto;
    &__text{
        text-align: center;
        font-size: 20px;
    }
}
.renderTextEmptyProduct.mini{
    min-height: 100px;
}