.dropdownDetails {
width: 100%;
&__header-left{
    display: flex;
align-items: center;

}
&__icon{
    margin: 0 20px ;
    height: 25px;
    width: 25px;

}
&__container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
    &__icons{
    margin-right: 10px;
    }

    &__container-button{
        max-width: 300px;
        height: 40px;
        margin: 20px;
        align-items: center;

    }
    &__button{
        width: 100%;
        height: 100%;
        background-color: #00a046;
        color: #fff;
        border: none;
        border-radius: 4px;
    }
 &__button:active{
        border: none;
    }
    &__edit-login-container{
        display: flex;
        cursor: pointer;
    }
    &__edit-login-container:hover{
       color: red;
    }
    &__edit-login-icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    }
    &__edit-login-text{
        font-size: 18px;
    }

}