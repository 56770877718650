.renderContact{
    max-width: 500px;
    margin: 20px auto;
    background-color: #cdcdcd;
    padding: 10px;
    &__buttonBlock{
        display:flex;
        justify-content: space-between;
    }
    &__contentBlock{
        text-align: center;
        min-height: 200px;
    }
}