.userProductHistory{
  
    &__items{
        max-height: 500px;
        overflow-y: auto;
        max-width: 500px;
        padding: 10px;
        margin: 5px auto;
    }
    &__item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 5px auto;
        cursor: pointer;
        &:hover{
            background-color: rgba(128, 128, 128, 0.2);
        }
    }
    &__imgBox{
        height: 40px;
        width: 80px;
        display: flex;
        justify-content: center;
        img{
            height: 100%;
            width: auto;
        }
    }
    &__infoContainer{
        margin: 10px auto;
        background-color: rgba(218, 218, 218, 0.418);
        border: 1px solid rgb(194, 194, 194);
        max-width: 250px;
        &__row{
            padding: 10px;
        }
        &__item{
            padding: 5px 0;
            display: flex;
                align-items: center;
                justify-content: space-between;
        }
    }
}