.key {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  // border: 1px solid black;
  font-family: sans-serif;
  color: #566856;
}

.value {
  font-size: 14px;
  font-family: sans-serif;
  color: #566856;
}

.productImgContainer {
  width: 100px;
  height: 130px;
  overflow: hidden;
  text-align: center;
}

.productImgContainer img {
  width: 100px;
  height: 130px;
  object-fit: contain;

}


.disk {
  margin: 20px  0;

  display: flex;
  align-items: center;
  flex-wrap:wrap;
  &__searchContainer{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__search {
    width: 200px;
    height: 40px;
    margin-left: 10px;
  }

  &__create {
    margin-left: 10px;
  }

  &__upload-input {
    display: none;
  }

  &__upload-label {
    width: 170px;
    height: 45px;
    outline: none;
    font-weight: 600;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    background: rgb(119, 140, 232);
 
    transition: 0.3s all;
    &:hover{
    background: rgba(0,0,0,.4);

    color: white;
    }
  }

  &__select {
    margin-left: auto;
    // margin-bottom: 10px;
    border: 1px solid rgb(119, 140, 232);
  }

  &__list,
  &__plate {
    height: 30px;
    width: 30px;
    padding: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    background: no-repeat center center;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    svg{
      height: 30px;
      width: 30px;
      color: #ffffff;
    }
    &:hover{
      background-color: rgba(0,0,0,0.3);
    }
   
  }
  &__list.active{
    svg{
    color: rgb(231, 170, 0);
}
}
  &__plate.active{
    svg{
    color: rgb(231, 170, 0);
}
}

  &__list {
    // background-image: url("../../assets/list.svg");
    position: absolute;
    right: 0;
  }

  &__plate {
    // background-image: url("../../assets/plate.svg");
    position: absolute;
    right: 45px;
  }
  @media (max-width: 700px){
    margin-bottom: 35px;

    display: block;
    align-items: center;
    flex-wrap:wrap;
  
    &__search {
      width: 100%;
      height: 40px;
margin: 20px auto;
    }
  
    &__create {
      margin-left: 10px;
    }
  
    &__upload-input {
      display: none;
    }
  
    &__upload-label {
      display: block;
      margin: 0 auto;
    }
  
    &__select {
      margin-left: auto;
      // margin-bottom: 10px;
      border: 1px solid rgb(119, 140, 232);
    }
  
    &__list,
    &__plate {
  display: none;
    }
  
    &__list {
      background-image: url("../../assets/list.svg");
      position: absolute;
      right: 20px;
    }
  
    &__plate {
      background-image: url("../../assets/plate.svg");
      position: absolute;
      right: 65px;
    }
  }
}

.product {
  margin: 10px 0;
  border-bottom: solid 1px #566856;
  display: grid;
  grid-template-columns: 1fr 4fr repeat(4, 1fr);
  align-items: center;
  &__renderTitleNameCategory{
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
      width: 30px;
      height: 30px;
      margin-left: 10px;
      color: #00000040;
      cursor: pointer;
      &:hover{
        color: #000000;
      }
    }
  }
  &:hover {
    transform: scale(1.01);
  }

  &:hover &__details {
    grid-column-start: 5;
    display: block;
  }

  &:hover &__delete {
    grid-column-start: 6;
    display: block;
  }
}

.fileplate {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;

}

.file-plate {
  height: 100%;
  margin-bottom: 20px;
}
 
.product-btn {
  &__product__details {
    font-size: 10px;
  }

  &__product__delete {
    font-size: 10px;
  }
}
.selectContainer1{
  margin: 0 auto;
}
