.card{
    // width: 100%;
    // background: rgb(255, 255, 255);
    // height: 74px;
    // transition: all .4s ease-in-out;
display: block;
}
// .card:hover{
//     background-color: #f3f3f3;
//     transition: all .2s ease-in-out;
// }
.cardHeader{
    display: flex;
    // padding:;
    border-bottom: 1px solid #e0e0e0;
}