.container-slider {
  width: 100%;
  height: 100%;   
  margin: auto 0;
  position: relative;
  transition: all 0.4;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

.sliderContainer{
  height: 100%;
  min-height: 500px;
  width: 100%;

}
&__slide1 {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}

&__slide1 img {
   display: block;
   max-width: 100%;
   min-height: 400px;
   height: auto;
   position: relative;
}

.active-anim {
  opacity: 1;

}

//Button
.btn-slide1 {
  width: 60px;
  height: 60px;
  background: #f1f1f1;
  border: 1px solid rgba(255, 255, 255, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  transition: all 0.4s;
  cursor: pointer;
  &:hover{
    background-color: rgb(255, 255, 255);
    opacity: 1;
  }
}
.prev {
  top: 50%;
  left: 13%;
  transform: translateY(-60%);
}
.next {
  top: 50%;
  right: 13%;
  transform: translateY(-60%);
}
@media(max-width:  500px){
  max-width: 800px;
  height: 300px;
  margin: auto 0;
  position: relative;
  transition: all 0.4;
  overflow: hidden;


&__slide1 {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;

}

&__slide1 img {
  display: block;
  max-width: 600px;
  max-height: 100%;
position: relative;
}

.active-anim {
  opacity: 1;

}

//Button
.btn-slide1 {
  width: 40px;
  height: 40px;
  // border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid rgba(255, 255, 255, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  transition: all 0.4s;
  cursor: pointer;
  &:hover{
    background-color: rgb(255, 255, 255);
    opacity: 1;
  }
}
.prev {
  top: 50%;
  left: 13%;
  transform: translateY(-60%);
}
.next {
  top: 50%;
  right: 13%;
  transform: translateY(-60%);
}
}
}



