.subscribersContent{

    &__row{
       
    }

    &__item{
        padding: 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid  rgba(151, 151, 151, 0.521);
    }
    &__item.success{
        background: rgba(0, 128, 0, 0.1);
    }
    &__item.error{
        background: rgba(255, 0, 0, 0.1);
    }
    &__imgBox{
        height: 80px;
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            height: 100%;
            width: auto;
        }
    }
    &__contentBox{
        height: 100px;
        position: relative;
        button{
            bottom: 5px;
            right: 5px;
            position: absolute;
        }
    }
    &__title{
        font-weight: 600;
        left: 0;
        width: 200px;
    }
    &__date{
        margin-top: 10px;
        span{
            font-weight: 600;
            margin-right: 5px;
        }
    }
}