.renderProductMiniList{


    &__row {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        max-height: 500px;
        overflow-y: auto;
      }
    &__imgBox{
        height: 100px;
        width: 150px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            height: 100%;
            width: auto;

        }
    }
    &__item{
        position: relative;
        margin: 5px;
        padding-right: 10px;
        border: 1px solid rgb(201, 201, 201);
    }
    &__inputOrder{
        position: absolute;
        right: 10px;
        top: -5px;
        width:  30px;
        height: 20px;
        // border-radius: 50%;
        text-align: center;
    }
    &__buttonBox{
        display: flex;
        flex-direction: column;
        gap: 10px; 
    }
    &__emptyProducts{
        
    }
}