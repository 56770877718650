.renderEmptyItem{

  max-width: 800px;
  margin: 0 auto;
  h3{
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
  }
  &__buttonContainer{
    text-align: center;
    margin: 20px 0;
    button{
      padding: 10px;
    }
  }
}



.file {
  min-height:200px;
  padding: 10px;
  border-bottom: solid 1px #566856;
  align-items: center;
  position: relative;
  
  &:hover{
    background-color: rgba(0, 0, 0, .2);
 }

  &__statusProductPictures{
    margin: 10px;
    padding-left: 20px;
  }
  &__mainPriceContainer{
    display: flex;
    align-items: center;
    margin-left: 10px;

    svg{
      width: 25px;
      height: 25px;
      color: #00000040;
      cursor: pointer;
      &:hover{
        background-color: #cfcfcf;
        color: #ff0000ce;
        border-radius: 50%;
      }
    }
  }
  &__mainSettingContainer{
    margin: 10px 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    svg{
      width: 25px;
      height: 25px;
      color: #00000040;
      cursor: pointer;
      &:hover{
        background-color: #cfcfcf;
        color: red;
        border-radius: 50%;
      }
    }
  }
  &__mainContainer{
    display: flex;
    width: 100%;

  }
  &__containerImg{
    display: flex;
    align-items: center;
    
    max-width: 300px;
    min-width: 300px;
  }
  &__containerImg.updatedPictures{
    border-left: 10px solid green;
  }
  &__img {
    display: flex;
    justify-content: center;
    max-width: 100%;
    margin: 5px auto;
    border-radius: 3%;
    align-items: center;
  }

&__buttonContainer{
 right: 10px;
 bottom: 10px;
 bottom: 10px;
 position: absolute;
}
&__contentBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  &__category {
    color: #b5b5b5;
  }
 
  &__date {
    justify-self: center;
  }

  &__name {
    padding-left: 10px;
    font-size: 18px;
    margin-top: 5px;
  }
  // &__mainContentContainer{

  // }
  &__timeBlock{
    color: #000;
    position: absolute;
    bottom: 0;
    left: 60px;
  }
  &__size {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    padding: 20px;
    margin-left: 10px;
    color: rgb(66, 66, 66);
    p{
      display: block;
      margin: 0;
    font-size: 16px;

    }
    span{
      margin-right: 10px;
      display: block;
      color: black;
      font-size: 12px;
    }
   
  }
  &__price{
    font-size: 16px;
    padding: 10px
  }
  button {
    text-decoration: none;
    background: #3d7a5f;
    color: rgb(255, 255, 255);
    margin: 5px 10px;
    padding: 10px 25px;
    position: relative;
    border: 1px solid #3d7a5f;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    transition: 0.3s all;
    outline:none;
&:hover{
  background: rgba(0,0,0,.1);
  color: #000000;

}
    &:hover:after {
      border-color: #f1c40f;
      width: calc(100% - 10px);
      height: calc(100% + 15px);
    }
  }
@media (max-width: 800px){
  &__buttonContainer{
    position: relative;
    margin-top: 20px;
  }
  &__mainContainer{
    display: block;
    // align-items: center;
    margin: 0 auto;
    text-align: center;
    width: 100%;
  }
  &__contentBox{
    display: block;
  }
  &__size {
    padding-top: 10px;
  color: #b5b5b5;
  }
  &__name {
    padding-left: 10px;
    font-size: 20px;
  }
  &__containerImg{
    max-width:350px;
    margin: 0 auto;
    position: relative;
  }
    &__img {
      margin: 0 auto;
      width: 250px;
      margin: 5px auto;
      border-radius: 3%;
      display: block;
      position: relative;
    }
}

}
.file.hideProduct{
  background-color: #00000018;
}
.file-title {
  margin: 10px;
  // border-bottom: solid 2px #b5b5b5;
  display: grid;
  grid-template-columns: 1fr 4fr repeat(4, 1fr);
  align-items: center;
  font-size: 16px;

  &__img {
    justify-self: center;
  color: #b5b5b5;

  }

  &__date {
    grid-column-start: 5;
    justify-self: center;
  color: #b5b5b5;

  }

  &__size {
    grid-column-start: 6;
    justify-self: center;
  color: #b5b5b5;
    
  }
}


.file-plate {
  max-width: 200px;
  height: 250px;
  margin: 15px auto;
  position: relative;
  &:hover {
    img {
      transform: scale(1.02);

    }
  }
&__imgContainer{
height: 200px;
width: 200px;
overflow: hidden;
cursor: pointer;
display: flex;
flex-direction: column;
align-items: center;
}


  img {
    // height: ;
    width: auto;
    max-height: 100%;
    border-radius: 5px;
  }

  &__btns {
    display: none;
    button{
      display: block;
  position: absolute;
    }

  }
&__buttons{
  padding: 10px;
  margin: 5px auto;
color: #ffffff;
  background: rgba(61, 122, 95,.8);
  transition: 0.3s all;
  border: 1px solid #3d7a5f;
  border: none;
  &:hover{
    background: rgba(0,0,0,.5);
  }
}
  &__name {
    text-align: center;
    margin-top: 10px;
    position: relative;
    &::after{
      content: '';
      width: 100%;
      bottom: -5px;
      left: 0;
      height: 1px;
      position: absolute;
      background-color: #000000;
    }
  }

  &:hover &__btns {
    display: block;
    z-index: 100;

    .product-btn__product__details {
      margin-right: 5px;
    }
  }
@media (max-width: 800px){

  &__btns {
    display: flex;
  top: 5px;
  button.setting{
    top: 10px;
    left: 10px;
  }
  button.delete{
    top: 10px;
    right: 10px;
  }

  }
}

}