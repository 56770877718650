.removeProductPictures{
    &__imgRow{
        display: flex;
        align-items: center;
        justify-content: left;
        overflow-x: auto;
        padding: 10px;
        max-width: 1000px;
        border-top: 1px solid gray;
        margin-top: 15px;
    }
    &__visibleImgIcon{
        position: absolute;
        left: 18px;
        bottom: 4px;
        svg{
            width: 20px;
            height: 20px;
            color: #00000040;
            cursor: pointer;
            &:hover{
              background-color: #cfcfcf;
              color: red;
              border-radius: 50%;
            }
          }
    }
    &__imgBox{
        border-right: 1px solid gray;
        padding-right: 10px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        img{
            height: 100%;
            margin-left: 5px;
            width: auto;
        }
    }
    &__imgBox.active{
        background-color: aquamarine;
    }
    &__checkbox{
        position: absolute;
        left: 10px;
        top: -28px;
    }
    &__buttonContainer{
        margin: 15px auto;
        text-align: center;
    }
}