.renderSettingCategoryModal{
    &__rowButtonContainer{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__buttonContainer{
        display: flex;
        align-items: center;
        justify-content: start;
        margin-left: 20px;
    }   
    &__titleRow{
        display: flex;
        align-items: center;
        justify-content: start;
    }
    &__iconContainer{
        margin-left: 10px;
    }


}
.buttonSettingCategoryShow, .buttonSettingCategoryShow{
    margin: 5px;
    height: 40px;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.037);
    border: 1px solid rgba(60, 60, 60, 0.54);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
    }
    &:hover{
        color: red;
        background-color: rgba(0, 0, 0, 0.117);

    }
}