.orderModalCenter{


    &__selectBlock{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__orderStatus{
        position: relative;
        width: 200px;
        height: 40px;
        border: 1px solid #cecece;
        margin: 20px auto;  
        cursor: pointer;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .15);
        transition: 0.3s all;
        z-index: 56;
        &:hover{
            background-color: #ececec;
            border-color: rgb(0, 149, 255);
        }
    .settingIconBlock{
        right: 10px;
    }
    }
    
    &__orderStatus.active{
        background-color: #00a046;
        border-color: rgb(83, 83, 83);
        
        span{
            color: white;
        }
    }
    &__line{
        position: absolute;
        bottom: -21px;
        right: 50%;
        height: 20px;
        width: 2px;
        background-color: #cecece;
        z-index: 55;
        &:last-child{
            display: none;
        }
    }
    &__line.active{
        position: absolute;
        height: 20px;
        width: 2px;
        background-color: #00a046;
    }
}